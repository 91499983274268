<template>
  <div class="login-l-inr">
    <div class="login-logo-w">
      <a href="/">
        <img src="../assets/img/redline-white-logo.svg" alt="" />
      </a>
    </div>
    <div class="login-l-middle">
      <h2>{{ $t("leads.memberPanel") }}</h2>
      <div class="service-list">
        <div class="service-item">
          <div>
            <img src="../assets/img/vinSearch.svg" alt="" />
            <span>{{ $t("login.vinSearch") }}</span>
            <!-- <span>{{ $t("login.vinSearch") }} & {{ $t("login.carHistoryReport") }}</span> -->
          </div>
        </div>
        <div class="service-item">
          <div>
            <img src="../assets/img/interchange.svg" alt="" />
            <span>{{ $t("login.interchange") }}</span>
          </div>
        </div>
        <div class="service-item">
          <div>
            <img src="../assets/img/inventory.svg" alt="" />
            <span>{{ $t("login.inventory") }}</span>
          </div>
        </div>
        <div class="service-item">
          <div>
            <img src="../assets/img/icons_invoice.svg" alt="" />
            <span>{{ $t("login.billingInvoicing") }}</span>
          </div>
        </div>
        <div class="service-item">
          <div>
            <img src="../assets/img/website.svg" alt="" />
            <span>{{ $t("login.yardWebsite") }}</span>
            <!-- <span>{{ $t("login.booklog") }}</span> -->
          </div>
        </div>
        <div class="service-item">
          <div>
            <img src="../assets/img/real-time.svg" alt="" />
            <span>{{ $t("login.realtimePost") }}</span>
            <!-- <span>{{ $t("login.post") }}</span> -->
          </div>
        </div>
        <div class="service-item">
          <div>
            <img src="../assets/img/marketplace.svg" alt="" />
            <span>{{ $t("login.inventoryMarketplace") }}</span>
            <!-- <span>{{ $t("login.ecommerce") }}</span> -->
          </div>
        </div>
        <div class="service-item">
          <div>
            <img src="../assets/img/Yard-marketing.svg" alt="" />
            <span>{{ $t("login.yardMarketing") }}</span>
            <!-- <span>{{ $t("login.auction") }}</span> -->
          </div>
        </div>
        <div class="service-item">
          <div>
            <img src="../assets/img/piston.svg" alt="" />
            <span>{{ $t("login.EnginesUp") }}</span>
            <!-- <span>{{ $t("login.auction") }}</span> -->
          </div>
        </div>
      </div>
    </div>
    <div class="login-l-btm">
      <div class="phone-dtl">
        <a href="tel:+1 714 334 5505">
          <img src="../assets/img/icon-phone-call.svg" />
          <p>
            {{ $t("leads.forHelpCallAdriaonat") }}
            <b>714-334-5505</b>
          </p>
        </a>
      </div>
      <div class="social-dtl">
        <ul>
          <li>
            <a :href="VUE_APP_RDL_INVENTORY_LINK" target="_blank">
              <img src="../assets/img/union.svg" />
            </a>
          </li>
          <li>
            <a :href="VUE_APP_FACEBOOK_LINK" target="_blank">
              <img src="../assets/img/fb.svg" />
            </a>
          </li>
          <li>
            <a :href="VUE_APP_INSTAGRAM_LINK" target="_blank">
              <img src="../assets/img/insta.svg" />
            </a>
          </li>
          <li>
            <a :href="VUE_APP_RDL_WHATSAPP_LINK" target="_blank">
              <img src="../assets/img/whatsapp.svg" />
            </a>
          </li>
          <li>
            <a :href="VUE_APP_RDL_YOUTUBE_LINK" target="_blank">
              <img src="../assets/img/youtube.svg" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PublicAside",
  data() {
    return {
      VUE_APP_FACEBOOK_LINK: process.env.VUE_APP_FACEBOOK_LINK,
      VUE_APP_INSTAGRAM_LINK: process.env.VUE_APP_INSTAGRAM_LINK,
      VUE_APP_RDL_INVENTORY_LINK: process.env.VUE_APP_INVENTORY_URL,
      VUE_APP_RDL_YOUTUBE_LINK: process.env.VUE_APP_YOUTUBE_LINK,
      VUE_APP_RDL_WHATSAPP_LINK: process.env.VUE_APP_WHATSAPP_LINK,
    };
  },
}
</script>
